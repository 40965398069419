<script>
  export let background = 'transparent' // white, light-grey, very-light-grey, dark, green
  export let horizontalPadding = 'default' // none, default
  export let verticalPadding = 'default' // none, short, default
  export let maxWidth = 'default'
  export let center = false
  export let additionalClass = ''

  const backgroundColorStyle = {
    transparent: 'bg-transparent',
    white: 'bg-white',
    'very-light-grey': 'bg-grey-100',
    'light-grey': 'bg-grey-200',
    'lighter-grey': 'bg-grey-300',
    'dark-grey': 'bg-grey-600',
    'dark-blue': 'bg-blue-500',
    green: 'bg-green',
    'green-300': 'bg-green-300',
    dark: 'bg-black',
    dark2: 'bg-black-1',
  }

  const horizontalPaddingStyle = {
    none: 'px-0',
    default: 'px-2.5',
    normal: 'px-4',
  }

  const maxWidthStyle = {
    sm: 'max-w-screen-sm',
    md: 'max-w-screen-md',
    lg: 'max-w-screen-lg',
    xl: 'max-w-screen-xl',
    '2xl': 'max-w-screen-2xl',
    default: 'max-w-[940px]',
  }

  const verticalPaddingStyle = {
    none: 'py-0',
    short: 'py-5',
    default: 'py-11',
  }
</script>

<section
  class="{horizontalPaddingStyle[horizontalPadding]} {verticalPaddingStyle[
    verticalPadding
  ]} {backgroundColorStyle[background]} {additionalClass}"
  class:text-center={center}
  class:text-white={['green', 'dark'].includes(background)}
>
  <div class="mx-auto {maxWidthStyle[maxWidth]}">
    <slot />
  </div>
</section>
